/* global $ */

import '../../css/app/components/home.scss';

class Home
{
    constructor () 
    {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.slick-carousel').slick({
                autoplay: true,
                arrows: false,
                dots: false,
                speed: 1000,
                autoplaySpeed: 4000,
                pauseOnHover:true
            });    
            $('.feature-gallery-carousal').slick({
                autoplay: true,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                cssEase: 'linear',
                speed: 1000,
                autoplaySpeed: 4000,
                pauseOnHover:false,
            });
        });
        console.log('Home initialised');
    }
}

new Home;